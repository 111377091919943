<script>
  import { mapActions } from "vuex";
  import MainCard from "../widgets/Cards/MainCard.vue";
  export default {
    components: {
      MainCard,
    },
    props: ["competition"],
    data() {
      return {
        options: {},
        statusLabels: {
          active: "Activo",
          pending: "Pendiente",
          inactive: "Inactivo",
          finished: "Finalizado",
        },
      };
    },
    computed: {
      ...mapActions("polls", ["getPollById"]),
    },
    methods: {
      formatDate(date) {
        if (!date) {
          return "";
        }
        return this.$global.dateLocaleFormat(date, this.options);
      },
    },
  };
</script>

<template>
  <MainCard class="cardContest">
    <template v-slot:wrapper>
      <div class="cardContest__details">
        <div class="cardContest__logo">
          <iconic name="competition" class="cardContest__logoIcon" />
        </div>
        <div class="cardContest__dataContainer">
          <h5 class="cardContest__data cardContest__data--title">{{ competition.name }}</h5>
          <p class="cardContest__data cardContest__data--subtitle">{{ competition.description }}</p>
        </div>
      </div>
      <div class="cardContest__footer">
        <div class="cardContest__dates">
          <div class="cardContest__start">
            <span class="cardContest__datename">Inicio:</span>
            <span>{{ ` ${formatDate(competition.startDate)}` }}</span>
          </div>
          <div class="cardContest__end">
            <span class="cardContest__datename">Finaliza:</span>
            <span>{{ ` ${formatDate(competition.endDate)}` }}</span>
          </div>
        </div>
        <p class="cardContest__status">{{ statusLabels[competition.status] }}</p>
      </div>
    </template>
  </MainCard>
</template>

<style lang="scss">
  .cardContest {
    cursor: pointer;
    &__details {
      @include Flex(row, space-between);
      width: 100%;
      gap: 10px;
    }
    &__footer {
      @include Flex(row, space-between, flex-end);
      width: 100%;
    }
    &__dataContainer {
      text-align: end;
    }
    &__logo {
      @include Flex(row);
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 6px;
      background: #e9e9e9;
      &--icon {
        font-size: 30px;
        color: #909090;
      }
    }
    &__logoIcon {
      font-size: 30px;
      color: #909090;
    }
    &__datename {
      font-weight: 900;
      color: black;
    }
    &__dates {
      font-size: 14px;
    }
    &__wrapper {
      width: 100%;
    }
    &__data {
      width: 100%;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &--title {
        font-size: 18px;
      }
      &--subtitle {
        font-size: 14px;
      }
    }
    &__status {
      font-size: 15px;
    }
    @media screen and (min-width: 450px) {
      &__data {
        max-width: 320px;
      }
    }
    @media screen and (min-width: 600px) {
      &__data {
        max-width: 400px;
      }
    }
    @media screen and (min-width: 768px) {
      &__data {
        max-width: 600px;
      }
      &__details {
        flex-direction: row-reverse;
      }
      &__dataContainer {
        text-align: start;
      }
      &__dates {
        @include Flex(row);
        gap: 10px;
      }
    }
    @media screen and (min-width: 1140px) {
      &__data {
        max-width: 800px;
        &--title {
          font-size: 20px;
        }
        &--subtitle {
          font-size: 16px;
        }
      }
      &__status {
        font-size: 16px;
      }
      &__dates {
        font-size: 16px;
      }
    }
  }
</style>
